import styled from '@emotion/styled';
import { MediaFragment } from '@graphql/generated/graphql';
import StrapiImage from '@lib/image/strapiImage';
import { ReactNode, memo, useMemo } from 'react';
import { isCustomChatAvatar } from '../utils/validators';

const CHAT_AVATAR_MEDIUM_SIZE = '2rem';
const CHAT_AVATAR_LARGE_SIZE = '3rem';

export type ChatAvatar = NonNullable<MediaFragment | ReactNode>;

export interface ChatAvatarProps {
    avatar: ChatAvatar;
    size?: 'md' | 'lg';
}

const Image = styled(StrapiImage)<{ size: string }>`
    object-fit: cover;
    border-radius: 100%;
    border: ${({ theme }) => `1px solid ${theme.palette.divider}`};
    height: ${({ size }) => size};
    width: ${({ size }) => size};
`;

const ChatAvatar = ({ avatar, size = 'md' }: ChatAvatarProps) => {
    const avatarSize = useMemo(
        () =>
            size === 'md' ? CHAT_AVATAR_MEDIUM_SIZE : CHAT_AVATAR_LARGE_SIZE,
        [size]
    );

    if (isCustomChatAvatar(avatar)) {
        return avatar;
    }

    return (
        <Image
            src={avatar.url}
            alt=''
            formats={avatar.formats}
            sizes={avatarSize}
            size={avatarSize}
        />
    );
};

export default memo(ChatAvatar);
