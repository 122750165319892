import Icon from '@components/utils/icons/icon';
import { AI } from '@constants';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Textarea, UIVariant } from '@lib/uikit';
import { useTranslation } from 'next-i18next';
import { useChatInput } from '../hooks/useChatInput';
import { ChangeEvent, FormEvent } from 'react';

export const INPUT_HEIGHT = 3;
export const INPUT_HEIGHT_IN_REM = `${INPUT_HEIGHT}rem`;
const INPUT_MAX_HEIGHT_IN_REM = `${INPUT_HEIGHT * 3}rem`;
const BUTTON_SIZE = 1.8;
const BUTTON_SIZE_IN_REM = `${BUTTON_SIZE}rem`;

interface ChatInputProps {
    value?: string;
    isDummy?: boolean;
    handleInputChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    handleSubmit?: (e?: FormEvent<HTMLFormElement>) => void;
}

const Form = styled.form`
    position: relative;
    min-height: ${INPUT_HEIGHT};
    max-height: ${INPUT_MAX_HEIGHT_IN_REM};
`;

const StyledTextarea = styled(Textarea)`
    padding-right: 50px;
    line-height: ${BUTTON_SIZE};
`;

const Button = styled.button`
    width: ${BUTTON_SIZE_IN_REM};
    height: ${BUTTON_SIZE_IN_REM};
    bottom: ${BUTTON_SIZE / 2}rem;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    right: ${({ theme }) => theme.spacing.xs};
    padding: ${({ theme }) => theme.spacing.xxs};
    color: ${({ theme }) => theme.palette.common.white};
    border-radius: ${({ theme }) => theme.shape.radius.sm};
    background: ${({ theme }) => theme.palette.common.black};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
`;

export const ChatInput = ({
    value,
    isDummy = false,
    handleInputChange,
    handleSubmit,
}: ChatInputProps) => {
    const { t } = useTranslation(AI);
    const theme = useTheme();
    const { chatInputRef, isDisabled, maybeBlur, maybeSubmit, resize, submit } =
        useChatInput({
            value,
            hasAutofocus: !isDummy,
            handleSubmit,
        });

    return (
        <Form onSubmit={submit}>
            <StyledTextarea
                ref={chatInputRef}
                value={value}
                resize='none'
                variant={UIVariant.Filled}
                height={INPUT_HEIGHT_IN_REM}
                maxHeight={INPUT_MAX_HEIGHT_IN_REM}
                placeholder={t('send')}
                onKeyDown={maybeSubmit}
                onKeyUp={() => resize(chatInputRef.current!)}
                onChange={handleInputChange}
                onFocusCapture={maybeBlur}
            />
            <Button type='submit' disabled={isDisabled}>
                <Icon name='send' color={theme.palette.common.white} />
            </Button>
        </Form>
    );
};
