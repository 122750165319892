import {
    FormEvent,
    KeyboardEvent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from 'react';
import { INPUT_HEIGHT_IN_REM } from '../components/ChatInput';
import { useRouter } from 'next/router';

interface useChatInputProps {
    value?: string;
    hasAutofocus?: boolean;
    handleSubmit?: (e?: FormEvent<HTMLFormElement>) => void;
}

export const useChatInput = ({
    value,
    hasAutofocus,
    handleSubmit,
}: useChatInputProps) => {
    const chatInputRef = useRef<HTMLTextAreaElement>(null);
    const {
        query: { initialInput },
    } = useRouter();

    const isDisabled = useMemo(() => value === '', [value]);

    const resize = useCallback((textarea: HTMLTextAreaElement) => {
        const lineCount = Math.ceil(
            textarea.scrollHeight / textarea.clientHeight
        );

        if (lineCount === 1) {
            return (textarea.style.height = INPUT_HEIGHT_IN_REM);
        }

        textarea.style.height = textarea.scrollHeight + 'px';
    }, []);

    useEffect(() => {
        if (chatInputRef.current) {
            resize(chatInputRef.current);
        }
    }, [resize]);

    useEffect(() => {
        if (!hasAutofocus) return;

        if (
            typeof initialInput === 'string' &&
            initialInput !== '' &&
            chatInputRef.current
        ) {
            const inputLength = chatInputRef.current.value.length;
            chatInputRef.current.setSelectionRange(inputLength, inputLength);
        }

        chatInputRef.current?.focus();
    }, [initialInput, hasAutofocus]);

    const maybeBlur = useCallback(() => {
        if (!hasAutofocus) chatInputRef.current?.blur();
    }, [hasAutofocus]);

    const resetSize = useCallback(() => {
        if (chatInputRef.current) {
            chatInputRef.current.style.height = INPUT_HEIGHT_IN_REM;
        }
    }, []);

    const submit = useCallback(
        (e?: FormEvent<HTMLFormElement>) => {
            handleSubmit?.(e);
            resetSize();
        },
        [handleSubmit, resetSize]
    );

    const maybeSubmit = useCallback(
        (event: KeyboardEvent<HTMLTextAreaElement>) => {
            if (!handleSubmit) return;
            const isSubmit = event.key === 'Enter' && !event.shiftKey;

            if (isSubmit) {
                event.preventDefault();
                submit();
            }
        },
        [handleSubmit, submit]
    );

    return {
        chatInputRef,
        isDisabled,
        maybeBlur,
        maybeSubmit,
        resetSize,
        resize,
        submit,
    };
};
