import styled from '@emotion/styled';
import Icon from './icon';
import { useEffect, useRef } from 'react';
import { applyNonOpaqueStyleRecursive } from '@utils/opacity';

const Container = styled.div`
    opacity: 1;
`;

export const LockedIcon = () => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        applyNonOpaqueStyleRecursive(ref.current);
    }, []);

    return (
        <Container ref={ref}>
            <Icon name='locked' />
        </Container>
    );
};
